const SearchIconSvgSecond = ({
  color = '#d12d34',
  height = 27,
  width = 28,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="search" clipPath="url(#clip0_19_1822)">
        <path
          id="Vector"
          d="M11.75 19.125C16.0992 19.125 19.625 15.5992 19.625 11.25C19.625 6.90076 16.0992 3.375 11.75 3.375C7.40076 3.375 3.875 6.90076 3.875 11.25C3.875 15.5992 7.40076 19.125 11.75 19.125Z"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M24.125 23.625L17.375 16.875"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_1822">
          <rect
            width="27"
            height="27"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIconSvgSecond;
